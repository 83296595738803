:root {
  // ALL COLORS OF ISPUI-DROPDOWN v1.6.5-feature-inputv6-component.0
  --ispui-dropdown-border: 1px solid var(--isp-dc-charlie-chaplin);

  --ispui-dropdown-bg-color: var(--isp-dc-cow);
  --ispui-dropdown-popup__container_box-shadow: var(--isp-box-shadow);
  --ispui-dropdown-min-width: 320px;
  --ispui-dropdown-min-height: 40px;

  --ispui-dropdown-scroll-background-color: var(--isp-c-inconspicuous);
  --ispui-dropdown-scroll-indicator-color: var(--isp-c-grey-weekdays);
  --ispui-dropdown-scroll-indicator-color_hover: var(--isp-c-blue-velvet);

  --ispui-dropdown__pointer-box-shadow: var(--isp-box-shadow);

  --ispui-close__gray-color: var(--isp-c-grey-weekdays);
  --ispui-close__gray-background-color_hover: var(--isp-dc-glass);
}
