:root {
  // our named variables
  --isp-text-color-main: var(--isp-dc-highway-star);

  --isp-checkbox-checked-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13'%3E%3Cpath d='M1 6.435l3.385 4.936L11 1.5' stroke-width='2' stroke='%231A76E2' fill='none' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --isp-checkbox-checked-image-disabled: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.24 6.54 6.78 12l6.04-9.86' stroke='%23797979' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

  --isp-font-weight-bold: 500;

  --isp-border-radius-main: 5px;

  --isp-box-shadow: 0px 1px 4px var(--isp-c-desolation-of-smaug);

  --isp-d-filter-invert: none;

  // SIZES
  --isp-sidebar-width: 275px;
  --isp-topbar-top-line-height: 45px;
  --isp-topbar-height: var(--isp-topbar-top-line-height);

  --ispui-tooltip-ellipsis__text-overflow: clip;
}

[data-color-scheme="dark"] {
  --isp-d-filter-invert: invert(1) sepia(2%) saturate(16%) hue-rotate(220deg)
    brightness(113%) contrast(80%);
}
