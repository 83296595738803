:root {
  // ALL COLORS OF ISPUI-INPUT v3.6.2-feature-focus-options.2
  // ispui-input
  --ispui-input__border-color: var(--isp-dc-charlie-chaplin);
  --ispui-input__border-color_active: var(--isp-dc-fremen);
  --ispui-input__text-color: var(--isp-dc-highway-star);
  --ispui-input__text-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-input__label-color: var(--isp-c-grey-weekdays);
  --ispui-input__background-color_disabled: var(--isp-dc-mimic);
  --ispui-input__background-color: var(--isp-dc-monochrome);
  --ispui-input__accent-color: var(--isp-dc-bright-emotions);
  --ispui-input__active-color: var(--isp-dc-fremen);

  --ispui-input__prefix-color: var(--isp-c-grey-weekdays);
  --ispui-input__prefix-color_disabled: var(--isp-c-grey-weekdays);

  // ispui-input clear button colors
  --ispui-input_regular__clear-button-background-color: transparent;
  --ispui-input_regular__clear-button-background-color_hover: var(
    --isp-dc-glass
  );
  --ispui-input_regular__clear-button-background-color_active: var(
    --isp-dc-glass
  );
  --ispui-input_regular__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_regular__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_regular__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_invalid__clear-button-background-color: var(
    --isp-dc-bright-emotions
  );
  --ispui-input_invalid__clear-button-background-color_hover: var(
    ---isp-dc-bright-emotions
  );
  --ispui-input_invalid__clear-button-background-color_active: var(
    --isp-c-mabel-pines
  );
  --ispui-input_invalid__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_invalid__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_invalid__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_disabled__clear-button-background-color: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-background-color_hover: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-background-color_active: var(
    --isp-c-grey-weekdays
  );
  --ispui-input_disabled__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_disabled__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_disabled__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );
  --ispui-input_focused__clear-button-background-color: transparent;
  --ispui-input_focused__clear-button-background-color_hover: var(
    --isp-dc-glass
  );
  --ispui-input_focused__clear-button-background-color_active: var(
    --isp-dc-glass
  );
  --ispui-input_focused__clear-button-cross-color: var(--isp-dc-highway-star);
  --ispui-input_focused__clear-button-cross-color_hover: var(
    --isp-dc-highway-star
  );
  --ispui-input_focused__clear-button-cross-color_active: var(
    --isp-dc-highway-star
  );

  // ispui-input validation icon
  --ispui-input-validation-icon-background-color_valid: var(
    --isp-c-grasshopper
  );
  --ispui-input-validation-icon-stroke-color_valid: var(--isp-dc-monochrome);
  --ispui-input-validation-icon-background-color_invalid: var(
    --isp-dc-bright-emotions
  );
  --ispui-input-validation-icon-stroke-color_invalid: var(--isp-dc-monochrome);

  // ispui-textarea scrollbar
  --ispui-scrollbar-width: 5px;
  --ispui-scrollbar-border-radius: 10px;
  --ispui-scrollbar-thumb-color: var(--isp-c-grey-weekdays);
  --ispui-scrollbar-thumb-color_hover: var(--isp-c-blue-velvet);
  --ispui-scrollbar-background-color: var(--isp-dc-charlie-chaplin);

  // ispui-password
  --ispui-password-icon-color: var(--isp-c-grey-weekdays);

  --ispui-input__label-left-offset: 15px;

  --ispui-input_has-prefix_ml: -2px;
}
