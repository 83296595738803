@import "../../../src/styles/variables/colors.scss";
@import "../../../src/styles/variables/variables.scss";
@import "../../../src/styles/overrides/ispui-button.scss";
@import "../../../src/styles/overrides/ispui-input.scss";
@import "../../../src/styles/overrides/ispui-link.scss";
@import "../../../src/styles/overrides/ispui-preloader-round.scss";
@import "./overrides/ispui-dropdown.scss";
@import "./overrides/ispui-select.scss";
@import "./overrides/ispui-checkbox.scss";

:root {
  --isp-font-size_regular: 14px;
  --isp-border-radius-main: 5px;
  --isp-field-height: 35px;
  --isp-field-width: 100%;
  --isp-field-padding-left: 10px;

  /** link theming */
  --ispui-font-size-regular: var(--isp-font-size_regular);

  /** input theming */
  --ispui-input__height: var(--isp-field-height);
  --ispui-input__width: var(--isp-field-width);
  --ispui-input__border-radius: var(--isp-border-radius);
  --ispui-input__border-color: var(--isp-dc-charlie-chaplin);
  --ispui-input__border-color_active: var(--isp-dc-fremen);
  --ispui-input__active-color: var(--isp-dc-fremen);
  --ispui-input__accent-color: var(--isp-dc-bright-emotions);
  --ispui-input__right-padding: 10px;
  --ispui-input__left-padding: var(--isp-field-padding-left);
  --ispui-input__label-left-offset: 10px;
  --ispui-input__label-fieldset-left-offset: -5px;

  /** ispui-password theming */
  ispui-password {
    display: contents;
  }

  /** select theming */
  --ispui-select-button-legend__border-color-focus: var(--isp-dc-fremen);
  --ispui-select-button-legend__legend-color-focus: var(--isp-dc-fremen);
  --ispui-select-button-legend__br: var(--isp-border-radius);
  --ispui-select-list__item-height: 35px;
  --ispui-select-list__max-height: 350px;
  --ispui-select-list__item-br: var(--isp-border-radius-main);
  --ispui-select-list__item-checkbox-pl: 15px;

  // this variables are not exist in ispui-select! Added manualy through builderParams! add them in library!
  --ispui-select-search__padding: 10px;
  --ispui-select-search__margin: 0;
  --ispui-select-list__margin: 0 0 0 10px;
  --ispui-select-list__padding: 0 8px 0 0;
  --ispui-select-list__min-width: calc(400px - 20px);
  --ispui-select-list__max-width: calc(400px - 20px);

  // form select dropdown class
  .ispui-login-form-select-dropdown {
    min-width: 400px;
    max-width: 400px;
    padding: 10px 0;
  }

  ispui-select-button-legend {
    // @TODO i.ablov add customization of select through css
    --ispui-select-button-legend__height: var(--isp-field-height);
    // @HACK important cause need to override initial width
    --ispui-select-button-legend__width: var(--isp-field-width) !important;
    --ispui-select-button-legend__pr: 20px;
    --ispui-select-button-legend__pl: var(--isp-field-padding-left);
    --ispui-select-button-legend__arrow-bottom: none;
  }

  .ispui-select-button-legend__legend {
    // @TODO i.ablov add customization to fieldset lable offset
    margin-left: 5px;
  }

  /** button theming */
  --ispui-button__line-height: 35px;
  --ispui-button__font: var(--isp-font-size_regular) Ubuntu, sans-serif;
  --ispui-button__padding-right: 15px;
  --ispui-button__padding-left: 15px;

  /** tooltip theming */
  --ispui-tooltip-background: var(--isp-c-moon-prism-power);
  --ispui-tooltip-font-main-color: var(--isp-c-malewicz);
  --ispui-tooltip-font-size: var(--isp-font-size_regular);
}

@import "./globals/globals.scss";
@import "./layout/layout.scss";
@import "./page/page.scss";
@import "./form/form.scss";

// ispui-dropdown and ispui-select overrides
.ispui-dropdown-content__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.ispui-dropdown-content {
  padding: 0;
}
.ispui-dropdown-popup__container {
  padding: 10px 0;
}
